<template>
    <div class="admin_page_body">
        <div class="refund_main">
            <div class="refund_info">
                <el-form ref="form" :model="form" size="small" label-position="left" :rules="rules" label-width="180px">
                    <div class="desc">
                        <h1>说明：</h1>
                        <p>·您对当前订单商品支付后想取消购买操作，且与商家协商达成一致退款，请填写"退款单"信息并提交</p>
                        <p>·退款/退货后，商家审核完成，交易钱款将原路径返回付款账户代码</p>
                    </div>

                    <div class="step" v-if="type === '1' || type === '2'">
                        <div class="item">
                            <div class="num">1</div>
                            <span class="text">买家申请退款</span>
                        </div>
                        <div class="item" :class="{on: type=== '1'}">
                            <div class="num">2</div>
                            <span class="text">商家处理退款申请</span>
                        </div>
                        <div class="item" :class="{on: type=== '2'}">
                            <div class="num">3</div>
                            <span class="text">退款完成</span>
                        </div>

                        <div class="line left"></div>
                        <div class="line right"></div>
                    </div>

                    <div class="step else" v-if="type === '3' || type === '4' || type === '5'">
                        <div class="item">
                            <div class="num">1</div>
                            <span class="text">买家申请退货</span>
                        </div>
                        <div class="item" :class="{on: type=== '3'}">
                            <div class="num">2</div>
                            <span class="text">商家处理退货申请</span>
                        </div>
                        <div class="item" :class="{on: type=== '4'}">
                            <div class="num">3</div>
                            <span class="text">买家退货给商家</span>
                        </div>
                        <div class="item" :class="{on: type=== '5'}">
                            <div class="num">3</div>
                            <span class="text">商家收货，退款完成</span>
                        </div>

                        <div class="line left"></div>
                        <div class="line center"></div>
                        <div class="line right"></div>
                    </div>

                    <div class="detail">
                        <div class="title">
                            <h1>买家{{desc}}申请</h1>
                        </div>

                        <div class="info">
                            <div class="item">
                                <div class="name">{{desc}}状态：</div>
                                <div class="text">商家处理中</div>
                            </div>

                            <div class="item">
                                <div class="name">{{desc}}单号：</div>
                                <div class="text">R202001011111020101</div>
                            </div>

                            <div class="item">
                                <div class="name">支付单：</div>
                                <div class="text">2021-06-01 11:00 7938082（¥222.98）</div>
                            </div>

                            <div class="item">
                                <div class="name">{{desc}}原因：</div>
                                <div class="text">其他</div>
                            </div>

                            <div class="item">
                                <div class="name">退款金额：</div>
                                <div class="text price">¥197.80</div>
                            </div>

                            <div class="item">
                                <div class="name">{{desc}}说明：</div>
                                <div class="text">商品重新买</div>
                            </div>

                            <div class="item" style="align-items: start">
                                <div class="name">上传凭证：</div>
                                <el-image
                                        style="width: 120px; height: 120px" class="ml10"
                                        src="https://fuss10.elemecdn.com/e/5d/4a731a90594a4af544c0c25941171jpeg.jpeg"
                                        :preview-src-list="['https://fuss10.elemecdn.com/e/5d/4a731a90594a4af544c0c25941171jpeg.jpeg']">
                                </el-image>
                            </div>

                            <template v-if="type === '4' || type === '5'">
                                <div class="item">
                                    <div class="name">物流公司：</div>
                                    <div class="text">商品重新买</div>
                                </div>
                                <div class="item">
                                    <div class="name">物流单号：</div>
                                    <div class="text">商品重新买</div>
                                </div>
                                <div class="item">
                                    <div class="name">物流凭证：</div>
                                    <el-image
                                            style="width: 120px; height: 120px" class="ml10"
                                            src="https://fuss10.elemecdn.com/e/5d/4a731a90594a4af544c0c25941171jpeg.jpeg"
                                            :preview-src-list="['https://fuss10.elemecdn.com/e/5d/4a731a90594a4af544c0c25941171jpeg.jpeg']">
                                    </el-image>
                                </div>
                            </template>
                        </div>

                        <div class="title">
                            <h1>商家{{desc}}处理</h1>
                        </div>

                        <div class="form" v-if="type === '1'">
                            <el-form-item label="审核状态：" prop="logisticsType">
                                <el-radio-group v-model="form.logisticsType">
                                    <el-radio :label="1">拒绝</el-radio>
                                    <el-radio :label="2">同意</el-radio>
                                </el-radio-group>
                            </el-form-item>

                            <el-form-item label=" 审核意见：" prop="logisticsId">
                                <el-input v-model="form.logisticsCode" type="textarea" :row="4" autocomplete="off" placeholder="请输入审核意见"></el-input>
                            </el-form-item>
                        </div>

                        <div class="info" v-if="type === '2'">
                            <div class="item">
                                <div class="name">审核状态：</div>
                                <div class="text">审核通过</div>
                            </div>

                            <div class="item">
                                <div class="name">审核意见：</div>
                                <div class="text">同意退款</div>
                            </div>
                        </div>

                        <div class="form" v-if="type === '3'">
                            <el-form-item label="审核状态：" prop="logisticsType">
                                <el-radio-group v-model="form.logisticsType">
                                    <el-radio :label="1">拒绝</el-radio>
                                    <el-radio :label="2">同意</el-radio>
                                </el-radio-group>
                            </el-form-item>

                            <el-form-item label=" 退货信息：" prop="logisticsId">
                                <el-input v-model="form.logisticsCode" type="textarea" :row="4" autocomplete="off" placeholder="例：
收货人：张三
联系电话：18810******
地址：辽宁省沈阳市大东区"></el-input>
                            </el-form-item>

                            <el-form-item label=" 审核意见：" prop="logisticsId">
                                <el-input v-model="form.logisticsCode" type="textarea" :row="4" autocomplete="off" placeholder="请输入审核意见"></el-input>
                            </el-form-item>
                        </div>

                        <div class="info" v-if="type === '4'">
                            <div class="item">
                                <div class="name">退货信息：</div>
                                <div class="text">张三 1881043**** <br />辽宁省沈阳市大东区龙之梦大厦3304</div>
                            </div>

                            <div class="item">
                                <div class="name">审核状态：</div>
                                <div class="text">审核通过</div>
                            </div>

                            <div class="item">
                                <div class="name">审核意见：</div>
                                <div class="text">同意退款</div>
                            </div>
                        </div>

                        <div class="form" v-if="type === '5'">
                            <el-form-item label=" 退货信息：" prop="logisticsId">
                                <span>张三 1881043**** <br />辽宁省沈阳市大东区龙之梦大厦3304</span>
                            </el-form-item>

                            <el-form-item label="审核状态：" prop="logisticsType">
                                <el-radio-group v-model="form.logisticsType">
                                    <el-radio :label="1">拒绝收货</el-radio>
                                    <el-radio :label="2">收货，并退款</el-radio>
                                </el-radio-group>
                            </el-form-item>

                            <el-form-item label=" 审核意见：" prop="logisticsId">
                                <el-input v-model="form.logisticsCode" type="textarea" :row="4" autocomplete="off" placeholder="请输入审核意见"></el-input>
                            </el-form-item>
                        </div>
                    </div>

                    <div class="submitBtn">
                        <el-form-item>
                            <el-button type="primary" :loading="isSubmitLoading" v-if="type === '1' || type === '3' || type === '5'">审核提交</el-button>
                            <el-button>返回</el-button>
                        </el-form-item>
                    </div>
                </el-form>
            </div>

            <div class="refund_order">
                <div class="info">
                    <div class="title">
                        <h1>订单信息</h1>
                    </div>

                    <div class="goods">
                        <div class="item">
                            <el-image src="https://fuss10.elemecdn.com/e/5d/4a731a90594a4af544c0c25941171jpeg.jpeg" class="image" fit="contain"></el-image>

                            <div class="desc">
                                <div class="name">东北野山参东北野山参东…</div>

                                <div class="bot">
                                    <div class="text">¥ 78.00</div>
                                    <div class="text">×2</div>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div class="title_sub">
                        <h1>订单金额：<span> ¥ 3000.00</span></h1>
                    </div>

                    <div class="order">
                        <div class="item">
                            <div class="text">订单编号：<span class="no">E129120200808110210</span><div class="more">更多></div></div>
                            <div class="text">物流编号：<span class="text">Y1129120200808110210</span></div>
                        </div>

                        <div class="item">
                            <div class="text">商家：<span class="text">东北制药</span></div>
                            <div class="text">客服电话：<span class="no">024-10208080</span></div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>

export default {
  name: "adminRefundDetail",
  data () {
    return {
      type: '1', // 1-退款处理，2-退款查看，3-退货处理，4-退货买家发货
      desc: '',
      form: {},
      rules: {},
      isSubmitLoading: false
    }
  },
  created() {
    this.$store.commit('changeScrollTop');
    if (this.type === '1' || this.type === '2') {
      this.desc = '退款'
    }
    if (this.type === '3' || this.type === '4' || this.type === '5') {
      this.desc = '退货'
    }
  },
  methods: {},
  components: {}
}
</script>
